import "./Servicos.css";
import ContainerServicos from "../Serviços/ContainerServicos";

function Servicos() {
  return (
    <div className="servicos" id="servicos">
      <div className="titulo-servico">
        <div className="conter-titulo">
          <h2>Serviços Oferecidos</h2>
          <span>Alguns de nosso serviços para o seu negócio!</span>
        </div>
      </div>
      <div className="componentes">
        <ContainerServicos
          imagem="../../../assets/site.png"
          alt="site"
          titulo="Sites e LandingPages"
          descricao="Ter um site que se adapte às telas do computador e celular é essencial para todo negócio e inovações."
        />
        <ContainerServicos
          imagem="../../../assets/infraestrutura.png"
          alt="infraestrutura"
          titulo="Infraestrutura de Rede"
          descricao="Possuir uma boa infraestrutura muda completamente toda sua gestão de controle sobre suas aplicações."
        />
        <ContainerServicos
          imagem="../../../assets/servidor.png"
          alt="gerenciamento-servidor"
          titulo="Gestão de Servidores"
          descricao="Uma boa gestão e controle de seus serviçõs gera um grande desempenho dentro do seu ambiente de trabalho."
        />

        <ContainerServicos
          imagem="../../../assets/montagem.png"
          alt="montagem-computador"
          titulo="Computadores"
          descricao="Computadores para escritórios, computadores gamers, computadores domésticos e manutenção."
        />
      </div>
    </div>
  );
}

export default Servicos;
