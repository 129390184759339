import './Rodape.css';

function Rodape() {
    return (
        <footer className='rodape'>
            <div className='esquerda-rodape'>
                <h4>Localização</h4>
                <div className='dv-contato'></div>
                <p>São Paulo, Piracicaba</p>

                <div className='linhas-contato'>
                    <img src="../../../assets/whatsapp.png" className='estilo-imagem' alt="" />
                    <p>Whatsapp: (19) 99839-8168</p>
                </div>
                <div className='linhas-contato'>
                    <img src="../../../assets/e-mail-rodape.png" className='estilo-imagem' alt="" />
                    <p>dream@dreamtechnology.com.br</p>
                </div>
            </div>
            <div className='centro-rodape'>
                <h4>Paginação</h4>
                    <div className='dv-contato'></div>

                <a className='link-contato' href="#home">HOME</a>
                <a className='link-contato' href="#sobre">SOBRE</a>
                <a className='link-contato' href="#servicos">SERVIÇOS</a>
                <a className='link-contato' href="#contato">CONTATO</a>
            </div>
            <div className='direita-rodape'>
                <h4>Redes</h4>
                <div className='dv-contato'></div>

                <div className='linhas-contato'>
                    <a href="#contato">
                        <img src="../../../assets/linkedin.png" className='estilo-imagem click' alt="" />
                    </a>
                    <a href="https://www.instagram.com/dreamtechnologybrazil/" target='_blank'>
                        <img src="../../../assets/instagram.png" className='estilo-imagem click' alt="" />
                    </a>
                    <a href="#contato">
                        <img src="../../../assets/facebook.png" className='estilo-imagem click' alt="" />
                    </a>
                </div>

                <h4>Serviços</h4>
                <div className='dv-contato'></div>

                <button className='btn-rodape'>
                    <a href="#servicos">
                        Saber Mais
                    </a>    
                </button>
            </div>
        </footer>
    );
}

export default Rodape;