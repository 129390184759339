import './Tecnologias.css';

function Tecnologias() 
{

    const estilo = {
        '--t': '15s'
    }

    return (
        <div className="tecnologia">
            <h1>ALGUMAS TECNOLOGIAS QUE UTILIZAMOS</h1>
            <div className="conter-tecnologia" style={estilo}>
                <div className="img-tec">
                    <img src="../../../assets/react.png" alt="react" />
                    <img src="../../../assets/bootstrap.png" alt="bootstrap" />
                    <img src="../../../assets/sql.png" alt="sql" />
                    <img src="../../../assets/javascript.png" alt="javascript" />
                    <img src="../../../assets/php.png" alt="php" />
                </div>
                <div className="img-tec">
                <img src="../../../assets/react.png" alt="react" />
                    <img src="../../../assets/bootstrap.png" alt="bootstrap" />
                    <img src="../../../assets/sql.png" alt="sql" />
                    <img src="../../../assets/javascript.png" alt="javascript" />
                    <img src="../../../assets/php.png" alt="php" />
                </div>
            </div>
        </div>
    );
}

export default Tecnologias;