import "./Formulario.css";
import React, { useRef, useState } from "react";

function Formulario() {
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [descricao, setDescricao] = useState("");
  const [msg, setMsg] = useState("");

  const nomeRef = useRef(null);
  const telefoneRef = useRef(null);
  const emailRef = useRef(null);
  const descricaoRef = useRef(null);

  //Validações
  const verificaNome = () => {
    if (nome.trim() === "") {
      nomeRef.current.focus();
      return false;
    } else {
      return true;
    }
  };

  const verificaTelefone = () => {
    if (telefone.trim() === "") {
      telefoneRef.current.focus();
      return false;
    } else {
      return true;
    }
  };

  const verificaEmail = () => {
    if (email.trim() === "") {
      emailRef.current.focus();
      return false;
    } else {
      return true;
    }
  };

  const verificaDescricao = () => {
    if (descricao.trim() === "") {
      descricaoRef.current.focus();
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      verificaNome() &&
      verificaTelefone() &&
      verificaEmail() &&
      verificaDescricao()
    ) {
      setMsg("Redirecionando para o WhatsApp...");
      // Abre o WhatsApp em uma nova aba
      const mensagem = `Nome: ${nome}\n********************** \n Telefone: ${telefone}\n********************** \n Email: ${email}\n********************** \n Descrição: ${descricao}`;
      const url = `https://api.whatsapp.com/send?phone=5519998398168&text=${encodeURIComponent(
        mensagem
      )}`;
      window.open(url, "_blank");

      // Limpa os campos do formulário
      setNome("");
      setTelefone("");
      setEmail("");
      setDescricao("");
    } else {
      setMsg("Preencha todos os campos!");
    }
  };

  return (
    <form className="form-contato blue" onSubmit={handleSubmit}>
      <input
        className="form-input"
        type="text"
        value={nome}
        id="nome"
        ref={nomeRef}
        placeholder="Nome"
        onChange={(e) => setNome(e.target.value)}
      />
      <input
        className="form-input"
        type="text"
        value={telefone}
        id="telefone"
        ref={telefoneRef}
        placeholder="Telefone"
        onChange={(e) => setTelefone(e.target.value)}
      />
      <input
        className="form-input"
        type="text"
        value={email}
        id="email"
        ref={emailRef}
        placeholder="E-mail"
        onChange={(e) => setEmail(e.target.value)}
      />
      <textarea
        className="form-textarea"
        name="descricao"
        value={descricao}
        id="descricao"
        ref={descricaoRef}
        onChange={(e) => setDescricao(e.target.value)}
      ></textarea>
      <button type="submit" className="btn-contato">
        ENVIAR
      </button>
      {msg && <p>{msg}</p>}
    </form>
  );
}

export default Formulario;
