import "./Sobre.css";
import React, { useRef, useEffect } from "react";

function Sobre() {
  const imgs = useRef(null);
  const img = useRef([]);

  let valor = 0;

  //Ajuda lidar com efeitos colaterais
  useEffect(() => {
    //Intervalo para alterar imagens
    const intervalo = setInterval(carrossel, 3000);

    //Limpa o intervalo quando o componente é desmontado
    return () => clearInterval(intervalo);
  }, []);

  //Alterar imagens
  function carrossel() {
    valor++;

    if (valor > img.current.length - 1) {
      valor = 0;
    }

    //Distancia de translação
    const translateX = -valor * 500;

    //Aplicando translação
    imgs.current.style.transform = `translateX(${translateX}px)`; // Use crase (`) para interpolação de string
  }

  return (
    <div className="sobre" id="sobre">
      <div className="conter-sobre">
        <div className="lateral-esquerda-sobre">
          <div className="carrossel-equipe" ref={imgs}>
            <img
              src="../../../assets/Equipe.jpg"
              alt="Equipe"
              ref={(ref) => (img.current[0] = ref)}
            />
            <img
              src="../../../assets/equipe2.jpg"
              alt="Equipe"
              ref={(ref) => (img.current[1] = ref)}
            />
            <img
              src="../../../assets/equipe3.jpg"
              alt="Equipe"
              ref={(ref) => (img.current[2] = ref)}
            />
          </div>
        </div>
        <div className="lateral-direita-sobre">
          <div className="conteudo-sobre">
            <span className="simbol"></span>
            <h3>Dream Technology</h3>
            <p>
              <span>A Dream Technology</span> se destaca no setor de tecnologia
              e inovação, oferecendo uma ampla variedade de serviços e soluções
              personalizadas para atender às necessidades dos nossos clientes.
              Nosso compromisso é garantir trabalhos de alta qualidade com
              prazos ágeis, um diferencial que nos posiciona de forma única no
              mercado. Nossa missão é trazer seguraça e estabilidade para o seu
              negócio contanto com foco em proporcionar o melhor atendimento e
              conforto a todos. Junte-se a nós e faça parte dessa história de
              sucesso!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sobre;
