import './Contato.css';
import Formulario from '../Formulario/Formulario';

function Contato() {
    return (

        <div className='contato' id='contato'>
            <div className='contato-esquerda'>
                <div className='conteudo-esquerda'>
                    <h3>Informações de contato</h3>

                    <div className='icones-contato'>
                        <div className='linhas'>
                            <img className='estilo-imagem' src='../../../assets/gps.png'></img>
                            <p>São Paulo, Piracicaba</p>
                        </div>
                        <div className='linhas'>
                            <img className='estilo-imagem' src='../../../assets/celular.png'></img>
                            <p>Whatsapp: (19) 99839-8168</p>
                        </div>
                        <div className='linhas'>
                            <img className='estilo-imagem' src='../../../assets/e-mail.png'></img>
                            <p>dream@dreamtechnology.com.br</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='contato-direita'>
                <Formulario />
            </div>
        </div>

    );
}

export default Contato;