import './ContainerServicos.css';

function ContainerServicos(props) {

    const { imagem, alt, titulo, descricao, link } = props;

    return (
        <div className="col-servico">
            <div className='flip'>
                <div className='frente'>
                    <div className='imagem-servico'>
                        <img src={imagem} alt={alt}/>
                    </div>
                    <h5>{titulo}</h5>
                    <p>{descricao}</p>
                </div>
                <div className='verso'>
                    <div className='imagem-servico-amp'>
                            <img src={imagem} alt={alt}/>
                        </div>
                    </div>
            </div>
        </div>
    );
}

export default ContainerServicos;