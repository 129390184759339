import React, { useRef } from "react";
import "./Navegation.css";
import { useTypewriter, Cursor } from "react-simple-typewriter";

import { TiThMenu } from "react-icons/ti";
import { MdOutlineClose } from "react-icons/md";


function Navegation() {
  const [text] = useTypewriter({
    words: [
      "Venha já realizar um orçamento totalmente gratuito e com o melhor preço do mercado.",
    ],
    typeSpeed: 30,
  });

  const menuMobileRef = useRef(null);
  const btnActive = useRef(null);
  const listaMenu = useRef(null);

  const open = () => {
    if (menuMobileRef.current) {
      btnActive.current.classList.add("none");
      menuMobileRef.current.classList.add("flex");
      listaMenu.current.style.display = "block";
    }
  };

  const close = () => {
    if (btnActive.current) {
      btnActive.current.classList.add("flex");
    }
    if (menuMobileRef.current) {
      menuMobileRef.current.classList.remove("flex");
    }
  };

  return (
    <div className="conter" id="home">
      <nav className="nav-wrapper">
        <div className="nav-content">
          <img className="logo" src="./assets/Logo.png" alt="Logo" />

          <ul>
            <li>
              <a className="menu-item" href="#home">
                Home
              </a>
            </li>
            <li>
              <a className="menu-item" href="#sobre">
                Sobre
              </a>
            </li>
            <li>
              <a className="menu-item" href="#servicos">
                Serviços
              </a>
            </li>
            <li>
              <a className="menu-item" href="#contato">
                Contato
              </a>
            </li>

            <button className="contact" onClick={() => {}}>
              <a
                className="link-btn"
                href="https://wa.me/19998398168"
                target="_blank"
              >
                SUPORTE
              </a>
            </button>
          </ul>

          <button className="menu-btn_ativar" ref={btnActive} onClick={open}>
           <div >
              <TiThMenu />
           </div>
          </button>

          <div className="menu-mobile" ref={menuMobileRef}>
            <button className="menu-btn" onClick={close}>
              <div>
                <MdOutlineClose/>
              </div>
            </button>

            <ul ref={listaMenu}>
              <li>
                <a className="menu-item" href="#home">
                  Home
                </a>
                <div className="dv-menu"></div>
              </li>
              <li>
                <a className="menu-item" href="#sobre">
                  Sobre
                </a>
                <div className="dv-menu"></div>
              </li>
              <li>
                <a className="menu-item" href="#servicos">
                  Serviços
                </a>
                <div className="dv-menu"></div>
              </li>
              <li>
                <a className="menu-item" href="#contato">
                  Contato
                </a>
                <div className="dv-menu"></div>
              </li>

              <button className="contact" onClick={() => {}}>
                <a
                  className="link-btn"
                  href="https://wa.me/19998398168"
                  target="_blank"
                >
                  SUPORTE
                </a>
              </button>
            </ul>
          </div>
        </div>
      </nav>

      <aside className="lateral-esquerda">
        <div className="conter-lateral-esquerda">
          <span>
            {text}
            <Cursor />
          </span>

          <button className="orcamento blue" onClick={() => {}}>
            <a
              className="link-btn"
              href="https://wa.me/19998398168"
              target="_blank"
            >
              ORÇAMENTO
            </a>
          </button>
        </div>
      </aside>
    </div>
  );
}

export default Navegation;
