import "./App.css";
import Navegation from "./componentes/navegation/Navegation";
import Tecnologias from "./componentes/Tecnologias/Tecnologias";
import Sobre from "./componentes/Sobre/Sobre";
import Servicos from "./componentes/Serviços/Servicos";
import Negocio from "./componentes/Negocio/Negocio";
import Contato from "./componentes/Contato/Contato";
import Rodape from "./componentes/Rodape/Rodape";
import Produzido from "./componentes/Produzido/Produzido";

function App() {
  return (
    <div className="App">
      <Navegation />
      <section className="container">
        <Tecnologias />
      </section>
      <section className="container-sobre">
        <Sobre />
      </section>
      <main className="container-servicos">
        <Servicos />
      </main>
      <section className="container-negocio">
        <Negocio msg="Estamos aqui para ajudar você a trazer estrutura ao seu negócio!" />
      </section>
      <section className="container-contato">
        <Contato />
      </section>
      <footer className="container-rodape">
        <Rodape />
      </footer>
      <section className="container-producao"></section>
    </div>
  );
}

export default App;
