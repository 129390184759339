import './Negocio.css';

function Negocio(props)  {

    const {msg} = props;

    return (
        <div className='negocio'>
            <div className='conteudo-negocio'>
                <div className='conteudo-acima'>
                    <span>{msg}</span>
                </div>

                <div className='conteudo-abaixo'>
                    <a className='btn-negocio' href='https://www.instagram.com/dreamtechnologybrazil/' target='_blank'>
                        CONFERIR
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Negocio;